import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import OtherProjects from "../../components/Other-Projects/otherProjects";

export default function FamilyTreeProject() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".words1", { types: "chars" });
    const textA = SplitType.create(".sentence1", { types: "words" });
    const textB = SplitType.create(".line1", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -20, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });
  return (
    <div className="project-section familytree-project container mt-120">
      <div className="project-hero-card bg-secondary radius-20 w-100 p-40 sm:p-20 xs:p-20 box1">
        <div className="project-hero-title mb-20 sm:mb-12 xs:mb-8">
          <h1 className="f-40 sm:f-32 xs:f-18 xs:l-h-24 l-h-40 f-w-600 text-primary words1">
            FamilyTree
          </h1>
          <p className="f-16 xs:f-14 xs:l-h-20 l-h-30 f-w-300 text-primary mt-8 sentence1">
            <span>Family Cloud / Web and Mobile</span>
          </p>
        </div>
      </div>
      <div className="mt-50 flex sm:flex-column xs:flex-column">
        <div className="mw-600 mr-40">
          <div>
            <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
              What is FamilyTree?
            </h3>
            <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
              Families can store their memories in the form of audio, video, and
              photo albums using the FamilyTree web and mobile application. They
              also have access to their complete relationship family tree. They
              can extend the family structure by inviting the members to grow
              their relationships.
            </p>
          </div>
          <div className="mt-30">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
              My Roles and responsibilities
            </h3>
            <div className="mt-12">
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
                To conduct user interviews, competitor analysis, user journey
                map, prototypes, and designs. Additionally, Create frontend
                screens and design systems for react.js applications. making an
                effort to complete the sprint tickets for every sprint.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:mt-24 xs:mt-20">
          <div className="bg-secondary w-100 radius-20 p-32 xs:p-12">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              Key Objectives
            </h3>
            <div className="mt-12">
              <p className="f-14 l-h-26 f-w-400 text-tertiary sentence1">
                Family Drive management
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Family Invite and relationship managements
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                File Storage for Photos, Audio and videos
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                File Sharing for access-tokens for relatives
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Subscription management for the cloud Storage
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-50 xs:mt-40 sm:mt-40 flex xs:flex-column">
        <div className="familytree-img1 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1"></div>
        <div className="familytree-img2 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1"></div>
      </div>

      <div className="mt-32 mw-860">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
          Key Problems and solutions{" "}
        </h3>
        <div className="mt-12">
          <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
            While creating a family tree structure, we tried two approaches to
            solving this problem. One structure looks like a thread, and the
            other resembles a tree structure. Our design team evaluated both
            wireframes.
          </p>
          <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
            Because of the complexity of understanding for the user, the first
            solution was not feasible based on wireframe testing. The second
            design, which resembles a family tree, was simple for users to grasp
            and apply effectively.
          </p>
        </div>
      </div>

      <div className="mt-32">
        <div className="familytree-research-1 bg-white radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 box1"></div>
        <div className="familytree-research-2 bg-white radius-20 p-32 xs:p-12 minh-400 sm:minh-400 xs:minh-200 mt-32 box1">
          <p className="bg-secondary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Family Tree Invite Flow
          </p>
        </div>
        <div className="familytree-research-3 bg-white radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="familytree-img4 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1">
          <p className="bg-secondary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Mobile design Handoff
          </p>
        </div>
        <div className="familytree-img5 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1">
          <p className="bg-secondary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Webview Final Handoff
          </p>
        </div>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Final Outcome{" "}
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          After successfully completing the user testing, we have changed the
          screen design based on realtime user feedback. Finally, After 1 years
          we the software beta version released to realtime usages. Over the
          period we have complete the 6 milestone with B2C product.
        </p>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Learning & Iteration
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          The next iteration of the familytree app. We have begun concentrating
          on user feedback and the milestones associated with the complex tree
          structure. As a Designer and Developer, I've gained knowledge about
          organizing drive-based apps and cloud storage. I gained knowledge on
          how to create clickable actions using the canvas background. However,
          this website still needs to be improved in a number of areas.
        </p>
      </div>

      <div className="text-center mt-60 mb-100 box1">
        <p className="f-20 mb-32 l-s-100">***</p>
        <OtherProjects />
      </div>
    </div>
  );
}
