import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";

import { Draggable } from "gsap/Draggable";
import Shape1 from "../../assets/images/shape.webp";

gsap.registerPlugin(Draggable);

function HeroSection() {
  // const { t } = useTranslation();
  useLayoutEffect(() => {
    const text = new SplitType(".target", { types: "chars" });
    const textA = new SplitType.create(".oneText", { types: "chars" });
    const textB = new SplitType.create(".oneTwo", { types: "words" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textA.chars,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );

    gsap.fromTo(
      ".home-drag",
      { width: 0, height: 0, opacity: 0 },
      { width: 26, height: 26, opacity: 1, delay: 2.4 }
    );
    gsap.fromTo(
      ".home-drag1",
      { width: 0, height: 0, opacity: 0 },
      { width: 16, height: 16, opacity: 1, delay: 1.8 }
    );
    gsap.fromTo(
      ".home-drag3",
      { width: 100, height: 100, opacity: 0 },
      { width: 200, height: 200, opacity: 1, delay: 2 }
    );

    Draggable.create(".img-drag", {
      inertia: true,
      bounds: ".container-one",
    });

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  return (
    <div className="hero-section relative flex align-center justify-center container-one">
      <div className="hero-section-content container">
        <div className="hero-position relative index-2 flex flex-column align-center xs:align-start">
          <h1 className="f-30 xs:f-18 f-w-300 text-secondary target">
            Hello I’m Anandakumar 👋
          </h1>
          <h2 className="f-60 xs:f-40 f-w-700 text-secondary oneText my-12">
            UI UX ENGINEER
          </h2>
          <p className="f-18 xs:f-14 l-h-30 f-w-300 text-tertiary text-center xs:text-left mw-650 oneTwo">
            I’m a designer-come developer. For more than seven years of working
            experience in B2C, B2B, and SaaS products, both Web and Mobile apps.
            My areas of expertise, end-to-end UI UX Design and front-end
            development.
          </p>
        </div>
        <div className="hero-position-img home-drag3 img-drag"></div>
      </div>
      <img src={Shape1} className="home-drag img-drag" alt="Shape star" />
      <img src={Shape1} className="home-drag1 img-drag" alt="Shape stars" />
      {/* <div className="stars"></div>
      <div className="stars1"></div>
      <div className="stars2"></div> */}
      <div className="shooting-stars"></div>
    </div>
  );
}

export default HeroSection;
