import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import OtherProjects from "../../components/Other-Projects/otherProjects";

export default function ExchangeProject() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".words1", { types: "chars" });
    const textA = SplitType.create(".sentence1", { types: "words" });
    const textB = SplitType.create(".line1", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -20, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });
  return (
    <div className="project-section exchange-project container mt-120">
      <div className="project-hero-card bg-secondary radius-20 w-100 p-40 sm:p-20 xs:p-20 box1">
        <div className="project-hero-title mb-20 sm:mb-12 xs:mb-8">
          <h1 className="f-40 xs:f-18 xs:l-h-24 sm:f-32 l-h-40 f-w-600 text-primary words1">
            CoinExchange
          </h1>
          <p className="f-16 xs:f-12 xs:l-h-20 sm:f-14 l-h-30 f-w-300 text-primary mt-8 sentence1">
            <span>Exchange / Web and Mobile</span>
          </p>
        </div>
      </div>
      <div className="mt-50 flex sm:flex-column xs:flex-column">
        <div className="mw-600 twm-unset mr-40 sm:mr-0 xs:mr-0">
          <div>
            <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
              What is CoinExchange?
            </h3>
            <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
              Coin Exchange is a centralized multifunctional web3 exchange for
              desktop, ios and android applications. Coin exchange provides
              users with Deep encryption, Multi-layer security and a transparent
              platform to trade, swap, buy and sell crypto currency.
            </p>
          </div>
          <div className="mt-30">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
              My Roles and responsibilities
            </h3>
            <div className="mt-12">
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
                My role is UI UX Design creating user research and user stories
                along with that data i have to create a journey map for each
                user actions in flow, pages, components, design-systems.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:mt-24 xs:mt-20">
          <div className="bg-secondary w-100 radius-20 p-32 xs:p-12">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              Key Objectives
            </h3>
            <div className="mt-12">
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Dynamic changing content and colors
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Depositing money into the spot wallet flow
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Maintain the components for both dark/light theme
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Create a complex exchange flows admin and user
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Spot trading fullscreen design
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-50 xs:mt-40 sm:mt-40 flex xs:flex-column">
        <div className="exchange-img1 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1"></div>
        <div className="exchange-img2 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1"></div>
      </div>

      <div className="mt-32 mw-860">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
          Key Analysis
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 mb-24 line1">
          Comprehensive analysis of the user interface journey across the
          platform. On the prototype screen after the flow has been finalized.
          We have a design sprint analysis every week. Whether the user is
          suitably matched by the application design. We must ensure that every
          element complements the light and dark themes.
        </p>
      </div>
      <div className="exchange-flow bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200">
        <p className="bg-tertiary f-12 text-secondary px-12 py-6 radius-20 inline-block">
          Major Project flow
        </p>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary mb-24">
          Components Spot trading Page
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 mb-24 line1">
          Trading on the spot Maintaining page design becomes more difficult
          when developing both light and dark themes. selecting a font and
          background color in accordance with accessibility color standards.
          Every second the numbers and views changes in the components, each of
          which has a unique proximity and design.
        </p>
        <div className="exchange-img3 bg-white radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 box1"></div>
        <div className="exchange-img4 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="exchange-img5 bg-secondary radius-20 p-32 xs:p-12 minh-660 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="exchange-img6 bg-secondary radius-20 p-32 xs:p-12 minh-660 sm:minh-450 xs:minh-200 mt-32 box1"></div>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
          Final Outcome{" "}
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          We successfully tested our initial phase and launched our internal
          customs. Throughout the course of the six-month project, discussions,
          design, and engineering challenges were addressed with every
          user-accessible area. As a design team, we have gained extensive
          knowledge regarding financial market applications and large exchanges.
          We continue to learn more and more about the intricacy of exchange
          applications every day.
        </p>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
          Learning & Iteration
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          In my opinion, every time I had a discussion about design with product
          teams, BAs, and engineers, it was a great challenge. We constantly
          have more comments to make regarding the screens and workflows. I now
          know how to organize and manage the modules of the large-scale
          project. Many areas still require improvement with each iteration.
        </p>
      </div>

      <div className="text-center mt-60 mb-100 box1">
        <p className="f-20 mb-32 l-s-100">***</p>
        <OtherProjects />
      </div>
    </div>
  );
}
