import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import OtherProjects from "../../components/Other-Projects/otherProjects";

export default function VCXProject() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".words1", { types: "chars" });
    const textA = SplitType.create(".sentence1", { types: "words" });
    const textB = SplitType.create(".line1", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -20, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });
  return (
    <div className="project-section vcx-project container mt-120">
      <div className="project-hero-card bg-secondary radius-20 w-100 p-40 sm:p-20 xs:p-20 box1">
        <div className="project-hero-title mb-20 sm:mb-12 xs:mb-8">
          <h1 className="f-40 xs:f-18 xs:l-h-24 sm:f-32 l-h-40 f-w-600 text-primary words1">
            VCX
          </h1>
          <p className="f-16 xs:f-12 xs:l-h-20 sm:f-14 l-h-30 f-w-300 text-tertiary mt-8 sentence1">
            <span>Virtual banking / Mobile Application</span>
          </p>
        </div>
      </div>
      <div className="mt-50 flex xs:flex-column sm:flex-column">
        <div className="mw-600 tmw-unset mr-40 sm:mr-0 xs:mr-0">
          <div>
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              About VCX
            </h3>
            <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
              We are a neo-banking service that is changing the fundamentals of
              how the customer does banking! Using our virtual cards We have
              proactively enhanced the Product to manage the customer's finances
              in an effective way by providing the best user experience to its
              customers through its innovative software and services.
            </p>
          </div>
          <div className="mt-30">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              My Roles and responsibilities
            </h3>
            <div className="mt-12">
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
                We have worked 3 members in this project design. My role is UI
                UX Design creating user research for the products. Create a
                wire-frame and prototype design.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:mt-24 xs:mt-16 xs:mb-24">
          <div className="bg-secondary w-100 radius-20 p-32 xs:p-12">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              Key Objectives
            </h3>
            <div className="mt-12">
              <p className="f-14 l-h-26 f-w-400 text-tertiary line1">
                Create a fresh design with limited research data
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary line1">
                Try to emphasis ourself as a customer take an interview with
                internal team
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Time duration for creating a entire flow and prototype for 32
                hours
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-32">
        <div className="vcx-userflow bg-white radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 box1">
          <p className="bg-tertiary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Main User Journey
          </p>
        </div>
        <div className="vcx-img1 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="vcx-img2 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Final Outcome{" "}
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          We were able to realize that an idea can change as a design and
          application when we completed the application in the extremely short
          amount of time. Conduct the research and development mainly using on
          design thinking methods. We have finally emerged victorious in the
          competition as the runner-up. Our confidence in the design execution
          within the team has increased.
        </p>
      </div>

      <div className="text-center mt-60 mb-100 box1">
        <p className="f-20 mb-32 l-s-100">***</p>
        <OtherProjects />
      </div>
    </div>
  );
}
