import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
// import i18n from "./i18n";
// import LocaleContext from "./LocaleContext";

import Homepage from "./containers/Homepage/homepage";
import AboutPage from "./containers/About/about";
import Resume from "./containers/Resume/resume";
import WorksPage from "./containers/Works/works";
import AnimatedCursor from "react-animated-cursor";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

import ExchangeProject from "./containers/Projects/exchange";
import FamilyTreeProject from "./containers/Projects/familytree";
import CRMProject from "./containers/Projects/crm";
import NFTProject from "./containers/Projects/nft";
import VCXProject from "./containers/Projects/vcx";
import TerapoolProject from "./containers/Projects/terapool";
import OtherDesignPage from "./containers/Other Designs/otherDesigns";
import PageNotPage from "./containers/PageNotFound/404page";

function App() {
  // const [locale, setLocale] = useState(i18n.language);

  //PreLoader Animation
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPreloader(false);
    }, 2500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* <LocaleContext.Provider
        value={{ locale, setLocale }}
      ></LocaleContext.Provider> */}
      <AnimatedCursor
        innerSize={8}
        outerSize={28}
        color="128, 128, 128"
        outerAlpha={0.2}
        innerScale={1}
        outerScale={2.5}
      />
      {preloader ? (
        <div className="preloader-section">
          <div className="drop"></div>
          <div className="relative index-2 text-center">
            <p className="f-30 f-w-300 text-secondary text1-reveal flex align-center justify-center">
              Hi <span className="f-18 ml-4">✨</span>
            </p>
            <p className="f-20 f-w-300 text-tertiary ml-0 mt-12 text2-reveal">
              Good Day! . Guten Tag!
            </p>
          </div>
        </div>
      ) : (
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="/works" element={<WorksPage />} />
            <Route path="/other-designs" element={<OtherDesignPage />} />
            <Route
              path="/project/coin-exchange"
              element={<ExchangeProject />}
            />
            <Route
              path="/project/family-tree"
              element={<FamilyTreeProject />}
            />
            <Route path="/project/vcx" element={<VCXProject />} />
            <Route path="/project/nft" element={<NFTProject />} />
            <Route path="/project/crm" element={<CRMProject />} />
            <Route path="/project/terapool" element={<TerapoolProject />} />
            <Route path="*" element={<PageNotPage />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
}
export default App;
