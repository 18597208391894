import React from "react";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// import ProjectOne from "../../assets/images/projects/project-1.png";
// import ProjectTwo from "../../assets/images/projects/project-2.png";
// import ProjectThree from "../../assets/images/projects/project-3.png";
// import ProjectFour from "../../assets/images/projects/project-4.png";
// import ProjectFive from "../../assets/images/projects/project-5.png";

export default function HomeWorkSection() {
  // const { t } = useTranslation();
  return (
    <div className="home-project-section pt-100 pb-100 sm:py-80 xs:py-60">
      <div className="container">
        <h3 className="f-32 f-w-700 title-gradient mb-50 xs:mb-32">
          .Selected Projects
        </h3>
        <div className="flex xs:flex-column xs:align-center xs:r-g-30 c-g-30 xs:minh-600 mb-30">
          <Link
            to="/project/crm"
            className="home-project home-crm flex-1"
          ></Link>
          <Link
            to="/project/coin-exchange"
            className="home-project home-exchange flex-1"
          ></Link>
        </div>
        <div className="flex xs:flex-column xs:align-center xs:r-g-30 c-g-30 xs:minh-600 mb-30">
          <Link
            to="/project/family-tree"
            className="home-project home-preserve flex-1"
          ></Link>
          <Link
            to="/project/nft"
            className="home-project home-vcx flex-1"
          ></Link>
        </div>
        <div className="flex xs:flex-column xs:align-center xs:r-g-30 c-g-30 xs:minh-600">
          <Link
            to="/project/coin-exchange"
            className="home-project home-nft flex-1"
          ></Link>
          <Link
            to="/project/terapool"
            className="home-project home-terapool flex-1"
          ></Link>
        </div>
      </div>
    </div>
  );
}
