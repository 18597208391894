import React, { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";

export default function PageNotPage() {
  const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".about1", { types: "chars" });
    const textA = SplitType.create(".about2", { types: "chars" });
    const textB = SplitType.create(".about3", { types: "words" });
    const textC = SplitType.create(".about4", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.chars,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textC.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -32, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  // const FadeUp = batch(Fade(), Move(), Sticky());

  return (
    <div className="container mt-120 mb-120 flex justify-center">
      <div className="text-center">
        <h1 className="f-60 f-w-400 l-h-30 text-secondary about2">
          {t("404Page.title")}
        </h1>
        <p className="f-20 f-w-400 l-h-36 text-tertiary mt-24 about3">
          {t("404Page.desc")}
        </p>
      </div>
    </div>
  );
}
