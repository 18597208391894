import React from "react";
import { useTranslation } from "react-i18next";

export default function OtherDesignPage() {
  const { t } = useTranslation();

  return (
    <div className="works-page py-80">
      <div className="container">
        <h3 className="f-32 f-w-700 text-secondary my-8 works1">
          {t("works.title")}
        </h3>
        <p className="f-14 l-h-26 f-w-300 text-tertiary mw-650 mt-18 works2">
          {t("works.desc")}
        </p>
      </div>
    </div>
  );
}
