import React from "react";
import HeroSection from "./hero";
import HomeWork from "./work";
// import CreatorSection from "./creator";
import ExpertSection from "./expertise";
import FaqSection from "../../components/FAQ/faq";

export default function Homepage() {
  return (
    <div className="landing-page">
      <div className="landing-page-section">
        <HeroSection />
        {/* <CreatorSection /> */}
        <HomeWork />
        <ExpertSection />
        <FaqSection />
      </div>
    </div>
  );
}
