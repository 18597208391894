import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import OtherProjects from "../../components/Other-Projects/otherProjects";

export default function NFTProject() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".words1", { types: "chars" });
    const textA = SplitType.create(".sentence1", { types: "words" });
    const textB = SplitType.create(".line1", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -20, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });
  return (
    <div className="project-section nft-project container mt-120">
      <div className="project-hero-card bg-secondary radius-20 w-100 p-40 sm:p-20 xs:p-20 box1">
        <div className="project-hero-title mb-20 sm:mb-12 xs:mb-8">
          <h1 className="f-40 xs:f-18 xs:l-h-24 sm:f-32 l-h-40 f-w-600 text-primary words1">
            MLB NFT
          </h1>
          <p className="f-16 xs:f-12 xs:l-h-20 sm:f-14 l-h-30 f-w-300 text-primary mt-8 sentence1">
            <span>NFT Platform / Mobile Android/IOS</span>
          </p>
        </div>
      </div>
      <div className="mt-50 flex xs:flex-column sm:flex-column">
        <div className="mw-600 mr-40">
          <div>
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              What is MLB NFT ?
            </h3>
            <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
              MLB NFT is a blockchain-based network for trading football plays
              that allows athletes and sports fans to share their best plays.
              MLB League stars are the primary focus of this platform. Moreover,
              NFTs are accessible for purchase, sale, and investment by anybody.
            </p>
          </div>
          <div className="mt-30">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              My Roles and responsibilities
            </h3>
            <div className="mt-12">
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
                I'm part of UI UX Designer and Developer. My role focusing on
                create eye catching NFT tokens ro easily buy and sell within the
                platform. Also creating a interactive landing page for the
                website. Fans easily connect with design.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:mt-24 xs:mt-16 xs:mb-24">
          <div className="bg-secondary w-100 radius-20 p-32 xs:p-12">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              Key Objectives
            </h3>
            <div className="mt-12">
              <p className="f-14 l-h-26 f-w-400 text-tertiary line1">
                Creating a best NFT Card designs
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Dynamic NFT Card Renderings
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Interactive landing Page designs
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-50 xs:mt-40 sm:mt-40 flex xs:flex-column">
        <div className="nft-img10 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1"></div>
        <div className="nft-img9 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1"></div>
      </div>

      <div className="mt-50 xs:mt-40 sm:mt-40 flex xs:flex-column">
        <div className="nft-img11 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1"></div>
        <div className="nft-img12 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1"></div>
      </div>

      <div className="mt-32 mw-860">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Nft Card Design Exploration{" "}
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
          We have created the card design based on MLB Baseball field structure.
          We took the outer shape convert to dymond structure for placing the
          athletes images and names.
        </p>
      </div>

      <div className="mt-32 xs:mt-40 sm:mt-40 flex xs:flex-column">
        <div className="nft-img1 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1">
          <p className="bg-secondary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            NFT Card Idea
          </p>
        </div>
        <div className="nft-img2 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1">
          <p className="bg-secondary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Card Structure
          </p>
        </div>
      </div>

      <div className="mt-32">
        <div className="nft-img3 bg-white radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 box1">
          <p className="bg-light-1 f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Final Card design
          </p>
        </div>
        <div className="mt-32 mw-860">
          <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
            Development Challenges{" "}
          </h3>
          <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
            Once we finalized the card design, we were facing the issues of
            matching the design to conversion react.js. We used SVG grouping for
            create the card design in development. For image rendering based on
            ID we used base64 URL.
          </p>
        </div>
        <div className="nft-img4 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="nft-img8 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Final Outcome{" "}
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          The client-side evaluation of the final beta website was positive, and
          the development and design teams put in a lot of effort to achieve
          this significant milestone for the NFT platform.
        </p>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Learning & Iteration
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          Based on transaction screens and NFT minting, design input was
          provided. We intended to update the iteration processes for the
          upcoming version. I've gained a lot of knowledge in design and
          development. handling the creative design primarily so that SVG
          dynamic renders the layouts.
        </p>
      </div>

      <div className="text-center mt-60 mb-100 box1">
        <p className="f-20 mb-32 l-s-100">***</p>
        <OtherProjects />
      </div>
    </div>
  );
}
