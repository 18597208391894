import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import OtherProjects from "../../components/Other-Projects/otherProjects";

export default function TerapoolProject() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".words1", { types: "chars" });
    const textA = SplitType.create(".sentence1", { types: "words" });
    const textB = SplitType.create(".line1", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -20, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });
  return (
    <div className="project-section terapool-project container mt-120">
      <div className="project-hero-card bg-secondary border-exlight radius-20 w-100 p-40 sm:p-20 xs:p-20 box1">
        <div className="project-hero-title mb-20 sm:mb-12 xs:mb-8">
          <h1 className="f-40 xs:f-18 xs:l-h-24 sm:f-32 l-h-40 f-w-600 text-primary words1">
            Terapool
          </h1>
          <p className="f-16 xs:f-12 xs:l-h-20 sm:f-14 l-h-30 f-w-300 text-primary mt-8 sentence1">
            <span>Crypto Fundraising / Web and Mobile</span>
          </p>
        </div>
      </div>
      <div className="mt-50 flex sm:flex-column xs:flex-column">
        <div className="mw-600 mr-40">
          <div>
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              What is Terapool?
            </h3>
            <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
              Terapool is a cryptocurrency based fund-raising platform. Terapool
              has #TERA among the Supported stable tokens ETH, USDT, and BNB for
              new tokens to create a project.
            </p>
          </div>
          <div className="mt-30">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              My Roles and responsibilities
            </h3>
            <div className="mt-12">
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 sentence1">
                I create user research, user stories, prototypes, and designs as
                a UI/UX Designer and Frontend Developer. Additionally,
                collaborating with the development team to create frontend
                screens and design systems for react.js applications. making an
                effort to complete the sprint tickets for every sprint.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:mt-24 xs:mt-20">
          <div className="bg-secondary w-100 radius-20 p-32 xs:p-12">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              Key Objectives
            </h3>
            <div className="mt-12">
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Contributors and investment blocks for every project's flow
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Metamask and Trust wallet connecting bridge
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Mobile application design for connecting the wallets
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Super admin / Admin / Fund managers and contributor
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-50 xs:mt-40 sm:mt-40 flex xs:flex-column">
        <div className="terapool-img1 bg-secondary border-exlight radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1"></div>
        <div className="terapool-img2 bg-secondary border-exlight radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1"></div>
      </div>

      <div className="mt-32">
        <div className="terapool-img3 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="terapool-img4 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1">
          <p className="bg-secondary f-12 text-secondary px-12 py-6 radius-20 inline-block">
            Project Minimal Roadmap
          </p>
        </div>
        <div className="terapool-img5 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="terapool-img6 bg-secondary radius-20 p-32 xs:p-12 minh-600 sm:minh-450 xs:minh-200 mt-32 box1"></div>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Final Outcome{" "}
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          We successfully tested after completing our milestone, and we archived
          the web 3.0 blockchain fundraising platform. Every module has been
          updated in response to beta suggestions from users. intricate
          processes, such as the management of individual contributions and fund
          contributions. Collectively, we have archived.
        </p>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
          Learning & Iteration
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          I have worked as a front-end developer and UI/UX designer throughout
          the application. I now know a great deal more about the workings of
          the web 3.0 platforms. collaborating closely with business managers
          and stakeholders. possess solid understanding of how the products'
          intended audience interacts with them. More about the upcoming modules
          and the iteration process.
        </p>
      </div>

      <div className="text-center mt-60 mb-100 sm:mb-80 box1">
        <p className="f-20 mb-32 l-s-100">***</p>
        <OtherProjects />
      </div>
    </div>
  );
}
