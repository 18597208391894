import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import Draggable from "gsap/Draggable";

import MyImage from "../../assets/images/anandakumar.png";
import MyImage1 from "../../assets/images/about1.png";
import MyImage2 from "../../assets/images/about2.png";
import MyImage3 from "../../assets/images/about3.png";
import Shape1 from "../../assets/images/shape.webp";

gsap.registerPlugin(Draggable);

// import {
//   Animator,
//   ScrollContainer,
//   ScrollPage,
//   batch,
//   Fade,
//   FadeIn,
//   Move,
//   MoveIn,
//   MoveOut,
//   Sticky,
//   StickyIn,
//   ZoomIn,
// } from "react-scroll-motion";

export default function AboutPage() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".about1", { types: "chars" });
    const textA = SplitType.create(".about2", { types: "chars" });
    const textB = SplitType.create(".about3", { types: "words" });
    const textC = SplitType.create(".about4", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.chars,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textC.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );
    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -32, opacity: 2, duration: 1 }
    );
    Draggable.create(".imd-drag", {
      inertia: true,
      bounds: ".container-one",
    });

    Draggable.create(".my-drag", {
      inertia: true,
      bounds: ".container-two",
    });

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  // const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
  // const FadeUp = batch(Fade(), Move(), Sticky());

  return (
    <div className="other-page-section">
      <div className="container flex justify-center">
        <div className="p-100 mw-1000 xs:mw-none xs:px-0 xs:pb-60 xs:mt-100 mt-120 container-two">
          <div className="flex justify-center mb-60 box1">
            <div className="my-image grid place-center container-one my-drag">
              <img
                src={MyImage}
                className="image-200"
                alt="anandakumar space"
              />
              <img
                src={Shape1}
                className="imd-drag image-30"
                alt="anandakumar design shape"
              />
            </div>
          </div>
          <p className="f-20 f-w-400 l-h-30 text-secondary mt-12 about2">
            Hi, I’m Anandakumar 👋
          </p>
          <p className="f-20 f-w-400 l-h-36 text-secondary mt-4 about3">
            For past 7+ years. I've been passionate about working, managing both
            UX design and development. I enjoy mentoring and learning new skills
            and technologies.
          </p>
          <div className="mt-40">
            <h3 className="f-20 xs:f-16 xs:l-h-28 f-w-600 l-h-36 text-link about1">
              Education
            </h3>
            <p className="f-20 xs:f-16 xs:l-h-28 f-w-400 l-h-36 text-tertiary mt-12 about4">
              I studied Bachelor of Computer Application (BCA) at St. Joseph's
              College in Trichy from 2013 to 2016. I've spent the majority of my
              time working on design, multimedia, performing arts, and sports.{" "}
            </p>
          </div>
          <div className="mt-32">
            <h3 className="f-20 xs:f-16 xs:l-h-28 f-w-600 l-h-36 text-link about1">
              Work Experience{" "}
            </h3>
          </div>
          <div className="mt-40">
            <h3 className="f-16 f-w-300 l-h-22 text-tertiary about2">
              (5.6 Years) 2018 - 2023{" "}
            </h3>
            <p className="f-20 xs:f-16 xs:l-h-28 f-w-600 l-h-36 text-secondary mt-4 about3">
              Lead UI UX Engineer / Calibraint Technologies{" "}
            </p>
            <p className="f-20 xs:f-16 xs:l-h-28 f-w-300 l-h-36 text-tertiary mt-12 about4">
              Working closely along with product team and stakeholders. Leading
              the design team with mentoring, training and guidance. Manage the
              entire project frontend design and development part. Design sprint
              and design process using Agile, Jira, Figma, Sketch, Adobe CC,
              HTML/css/JS/TypeScript, ReactJS and Angular.
            </p>
            <div className="mt-16">
              <p className="f-14 f-w-300 l-h-22 text-tertiary about3">
                📚 Speaker, Mentor and trainer for Design Team{" "}
              </p>
              <p className="f-14 f-w-300 l-h-22 text-tertiary my-12 about3">
                🎉 Star Performer UI UX Team 2021{" "}
              </p>
              <p className="f-14 f-w-300 l-h-22 text-tertiary about3">
                🎉 Best found of the year 2018{" "}
              </p>
            </div>
            <div className="mt-40">
              <p className="f-16 f-w-300 l-h-22 text-tertiary about3">
                (1.3 Years) 2016 - 2018{" "}
              </p>
              <p className="f-20 xs:f-16 xs:l-h-28 f-w-600 l-h-36 text-secondary mt-4 about3">
                UI UX Designer and Frontend Developer / Trusfi Technologies{" "}
              </p>
              <p className="f-20 xs:f-16 xs:l-h-28 f-w-300 l-h-36 text-tertiary mt-12 about4">
                Designing and managing the full product UI UX frontend process.
                artwork based on the product, logos, icons, user research,
                wireframing, and prototypes. Adobe XD, Javascript, Jquery,
                React.js, and HTML/SCSS.
              </p>
            </div>
            <div className="mt-40 mb-100 xs:mb-60">
              <p className="f-20 xs:f-16 xs:l-h-28 f-w-600 l-h-36 text-link about4">
                Personal Interest{" "}
              </p>
              <p className="f-20 xs:f-16 xs:l-h-28 f-w-300 l-h-36 text-tertiary mt-12 about4">
                I am a firm believer that hobbies and habits play important
                roles in my career and personal life when it comes to making
                decisions and imagining possibilities. I enjoy reading, writing,
                designing, cooking, playing the guitar, dancing, and sports.{" "}
              </p>
              <p className="f-20 xs:f-16 xs:l-h-28 f-w-300 l-h-36 text-tertiary mt-20 about4">
                The Ultimate aim for us. We have to cultivate the society by
                using a assailable medium like design, art, literature, or any
                form of creations. I adore taking part in these things.{" "}
              </p>
              {/* <p className="f-12 f-w-300 l-h-24 text-tertiary text-uppercase mt-32">
              EDUCATE  .  AGITATE  .  ORGANISE            
              </p> */}
            </div>
            <div className="flex justify-center">
              <img
                src={MyImage1}
                alt="anandakumar tools"
                className="image-120 xs:image-80 box1"
              />
              <img
                src={MyImage2}
                alt="anandakumar mentoring"
                className="image-120 xs:image-80 mx-100 xs:mx-32 box1"
              />
              <img
                src={MyImage3}
                alt="anandakumar teach"
                className="image-120 xs:image-80 box1"
              />
            </div>
            <div className="text-center mt-40">
              <p className="f-20 mb-40 l-s-100">***</p>
              <p className="f-12 f-w-300 l-h-22 text-tertiary text-uppercase about4">
                <span>Nandri</span>
                <span className="mx-24">Thanks</span>
                <span>Danke</span>
              </p>
            </div>
          </div>
        </div>

        {/* <ScrollContainer>
        <ScrollPage page={0}>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -300))}>
            <span style={{ fontSize: "3em" }}>Hello 👋</span>
          </Animator>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
            <h1 style={{ fontSize: "10em" }}>Anandakumar 👋</h1>
          </Animator>
        </ScrollPage>
        <ScrollPage page={1}>
          <Animator animation={ZoomInScrollOut}>
            <span style={{ fontSize: "1em" }}>I'm Anandakumar ✨</span>
          </Animator>
        </ScrollPage>
        <ScrollPage page={2}>
          <Animator animation={FadeUp}>
            <span style={{ fontSize: "3em" }}>I'm FadeUp ⛅️</span>
          </Animator>
        </ScrollPage>
        <ScrollPage page={3}>
          <div>
            <span style={{ fontSize: "3em" }}>
              <Animator animation={MoveIn(-1000, 0)}>Hello Guys 👋🏻</Animator>
              <Animator animation={MoveIn(1000, 0)}>
                Nice to meet you 🙋🏻‍♀️
              </Animator>
              - I'm Seonghyeok -
              <Animator animation={MoveOut(1000, 0)}>Good bye ✋🏻</Animator>
              <Animator animation={MoveOut(-1000, 0)}>See you 💛</Animator>
            </span>
          </div>
        </ScrollPage>
        <ScrollPage page={4}>
          <Animator animation={batch(Fade(0.7), Sticky(), MoveOut(0, -800))}>
            <span style={{ fontSize: "3em" }}>Done</span>
            <span style={{ fontSize: "3em" }}>
              There's FadeAnimation, MoveAnimation, StickyAnimation,
              ZoomAnimation
            </span>
          </Animator>
        </ScrollPage>
      </ScrollContainer> */}
      </div>
    </div>
  );
}
