import React, { useLayoutEffect } from "react";
import SplitType from "split-type";
import { gsap } from "gsap";
// import { useTranslation } from "react-i18next";
import ChatImg from "../../assets/images/chat.png";

function MailIcon(props) {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.69229 23.6673C1.92521 23.6673 1.28472 23.4104 0.770833 22.8965C0.256944 22.3826 0 21.7421 0 20.975V3.02628C0 2.25919 0.256944 1.61871 0.770833 1.10482C1.28472 0.590929 1.92521 0.333984 2.69229 0.333984H27.3077C28.0748 0.333984 28.7153 0.590929 29.2292 1.10482C29.7431 1.61871 30 2.25919 30 3.02628V20.975C30 21.7421 29.7431 22.3826 29.2292 22.8965C28.7153 23.4104 28.0748 23.6673 27.3077 23.6673H2.69229ZM15 12.193L1.66667 3.47503V20.975C1.66667 21.2742 1.76282 21.5199 1.95513 21.7122C2.14743 21.9045 2.39315 22.0007 2.69229 22.0007H27.3077C27.6068 22.0007 27.8526 21.9045 28.0449 21.7122C28.2372 21.5199 28.3333 21.2742 28.3333 20.975V3.47503L15 12.193ZM15 10.334L27.8205 2.00065H2.1795L15 10.334ZM1.66667 3.47503V2.00065V20.975C1.66667 21.2742 1.76282 21.5199 1.95513 21.7122C2.14743 21.9045 2.39315 22.0007 2.69229 22.0007H1.66667V3.47503Z"
        fill="white"
      />
    </svg>
  );
}

function EmailBackground(props) {
  return (
    <svg
      width="200"
      height="200"
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_f_267_362)">
        <circle cx="100" cy="100" r="50" fill="url(#paint0_linear_267_362)" />
      </g>
      <defs>
        <filter
          id="filter0_f_267_362"
          x="0"
          y="0"
          width="200"
          height="200"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="25"
            result="effect1_foregroundBlur_267_362"
          />
        </filter>
        <linearGradient
          id="paint0_linear_267_362"
          x1="74"
          y1="56"
          x2="123"
          y2="150"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5DDB6A" />
          <stop offset="0.253357" stopColor="#302EFF" />
          <stop offset="0.499941" stopColor="#FF9500" />
          <stop offset="0.73846" stopColor="#9018FF" />
          <stop offset="1" stopColor="#F900FE" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default function Footer() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    gsap.to(".rotate", {
      rotation: "+=360",
      repeat: -1,
      duration: 2,
    });
    gsap.to(".icon-scale", {
      scale: 1,
      duration: 3,
    });

    const words = new SplitType("#targetOne", { types: "words, chars" });
    gsap.from(words.chars, { y: 15, stagger: 0.2 });
    gsap.to(".stagger .span", {
      y: -30,
      repeat: -1,
      yoyo: true,
      stagger: {
        each: 0.2,
        from: "start",
      },
    });
    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  return (
    <div className="footer-section pt-100 sm:pt-80 xs:pt-60">
      <div className="container">
        <div className="flex xs:flex-column">
          <div className="flex-1">
            <img
              src={ChatImg}
              className="image-120 sm:image-100 xs:image-80 img-max-width"
              alt="Shall we discuss about the project?"
            />
            <h3 className="f-100 sm:f-60 xs:f-40 f-w-400 mt-30">Let’s talk.</h3>
            <div className="flex flex-wrap r-g-20 c-g-12 mw-330 mt-40">
              <a
                href="https://www.linkedin.com/in/jeoanand/"
                target="_blank"
                rel="noreferrer"
                className="bg-secondary radius-100 f-14 xs:f-12 text-secondary xs:px-20 xs:py-10 px-24 py-14 social-link"
              >
                Linkedin
              </a>
              <a
                href="https://www.behance.net/jeoanand"
                target="_blank"
                rel="noreferrer"
                className="bg-secondary radius-100 f-14 xs:f-12 text-secondary xs:px-20 xs:py-10 px-24 py-14 social-link"
              >
                Behance
              </a>
              <a
                href="https://github.com/jeoanand"
                target="_blank"
                rel="noreferrer"
                className="bg-secondary radius-100 f-14 xs:f-12 text-secondary xs:px-20 xs:py-10 px-24 py-14 social-link"
              >
                Github
              </a>
              <a
                href="https://www.xing.com/profile/Anandakumar_AgushtheenJeyaraj/cv"
                target="_blank"
                rel="noreferrer"
                className="bg-secondary radius-100 f-14 xs:f-12 text-secondary xs:px-20 xs:py-10 px-24 py-14 social-link"
              >
                Xing
              </a>
              <a
                href="https://www.instagram.com/jeoanand"
                target="_blank"
                rel="noreferrer"
                className="bg-secondary radius-100 f-14 xs:f-12 text-secondary xs:px-20 xs:py-10 px-24 py-14 social-link"
              >
                Instagram
              </a>
            </div>
            <p className="f-14 sm:f-12 xs:f-10 l-h-22 f-w-400 text-tertiary mt-80 xs:mt-32">
              @ 2024 Anandakumar. Made with ❤️ Figma/React/Gsap.
            </p>
          </div>
          <div className="flex-1 flex align-center justify-end sm:justify-center">
            <a
              href="mailto:anandakumar.je@gmail.com"
              className="block email-button email-bg image-160 radius-circle bg-secondary grid place-center sm:ml-50"
            >
              <div className="mail-bg rotate">
                <EmailBackground />
              </div>
              <div className="icon-scale xs:none">
                <MailIcon />
              </div>
              <p className="f-20 none xs:block">✉️</p>
            </a>
          </div>
        </div>
        <div className="flex justify-center mt-80 sm:mt-40 footer-name">
          {/* <p className="f-150 title-gradient-top l-h-0 f-w-700">Anandakumar</p> */}
          <div className="f-150 sm:f-100 xs:f-42 text-hint footer-text l-h-0 f-w-700 stagger flex">
            <span className="span">A</span>
            <span className="span">n</span>
            <span className="span">a</span>
            <span className="span">n</span>
            <span className="span">d</span>
            <span className="span">a</span>
            <span className="span">k</span>
            <span className="span">u</span>
            <span className="span">m</span>
            <span className="span">a</span>
            <span className="span">r</span>
          </div>
        </div>
      </div>
    </div>
  );
}
