import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";

export default function WorksPage() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".works1", { types: "chars" });
    const textA = SplitType.create(".works2", { types: "words" });
    const textB = SplitType.create(".works3", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".stagger .box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -24, opacity: 2, stagger: 0.1, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  return (
    <div className="works-page other-page-section pt-160 pb-100 xs:pt-120 xs:pb-80">
      <div className="container">
        <div className="flex flex-column align-center">
          <h3 className="f-32 f-w-700 text-secondary text-center my-8 works1">
            .Project Works
          </h3>
          <p className="f-14 l-h-26 f-w-300 text-tertiary text-center mw-650 mt-12 mb-24 works2">
            * This portfolio's case studies are mostly covered by non-disclosure
            agreements (NDAs). Therefore, in order to preserve the projects'
            confidentiality, I have hidden some information.
          </p>
        </div>
        <div className="mt-40">
          <div className="flex flex-wrap sm:flex-wrap xs:flex-column c-g-30 r-g-30 stagger">
            <Link
              to="/project/crm"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            >
              <div className="works-project-card crm-logo"></div>
            </Link>
            <Link
              to="/project/family-tree"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            >
              <div className="works-project-card familytree-logo"></div>
            </Link>

            <Link
              to="/project/vcx"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            >
              <div className="works-project-card vcx-logo"></div>
            </Link>
            <Link
              to="/project/coin-exchange"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            >
              <div className="works-project-card exchange-logo"></div>
            </Link>
            <Link
              to="/project/nft"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            >
              <div className="works-project-card nft-logo"></div>
            </Link>
            <Link
              to="/project/terapool"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            >
              <div className="works-project-card terapool-logo"></div>
            </Link>
            {/* <Link
              to="/project/crm"
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 minw-330 sm:minw-330 box1"
            ></Link> */}
          </div>
        </div>
      </div>
      {/* <div className="container mt-60">
        <h3 className="f-32 f-w-700 text-secondary my-8 works1">
          .Other Works
        </h3>
        <div className="mt-40">
          <div className="flex c-g-30 stagger">
            <a
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 box1"
              href="/project/coin-exchange"
              target="_blank"
              rel="noreferrer"
            >
              <div className="works-project-card pdf-logo"></div>
            </a>
            <a
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 box1"
              href="https://codepen.io/jeoanand/collections/"
              target="_blank"
              rel="noreferrer"
            >
              <div className="works-project-card codepen-logo"></div>
            </a>
            <a
              className="flex-1 overflow-hidden bg-secondary radius-20 minh-340 box1"
              href="https://www.figma.com/@jeoanand"
              target="_blank"
              rel="noreferrer"
            >
              <div className="works-project-card figma-logo"></div>
            </a>
          </div>
        </div>
      </div> */}
    </div>
  );
}
