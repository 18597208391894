import React from "react";
import { Link } from "react-router-dom";

export default function OtherProjects() {
  return (
    <div>
      <div className="flex flex-wrap justify-center space-ml-8 xs:c-g-12 xs:r-g-12">
        <Link
          to="/project/crm"
          className="bg-secondary radius-100 xs:f-12 f-14 text-secondary xs:px-16 px-24 xs:py-12 py-16"
        >
          CRM
        </Link>
        <Link
          to="/project/family-tree"
          className="bg-secondary radius-100 xs:f-12 f-14 text-secondary xs:px-16 px-24 xs:py-12 py-16"
        >
          FamilyTree
        </Link>
        <Link
          to="/project/coin-exchange"
          className="bg-secondary radius-100 xs:f-12 f-14 text-secondary xs:px-16 px-24 xs:py-12 py-16"
        >
          CoinExchange
        </Link>
        <Link
          to="/project/vcx"
          className="bg-secondary radius-100 xs:f-12 f-14 text-secondary xs:px-16 px-24 xs:py-12 py-16"
        >
          VCX
        </Link>
        <Link
          to="/project/nft"
          className="bg-secondary radius-100 xs:f-12 f-14 text-secondary xs:px-16 px-24 xs:py-12 py-16"
        >
          NFT
        </Link>
        <Link
          to="/project/terapool"
          className="bg-secondary radius-100 xs:f-12 f-14 text-secondary xs:px-16 px-24 xs:py-12 py-16"
        >
          Terapool
        </Link>
      </div>
    </div>
  );
}
