import React, { useState, useEffect } from "react";
// import Modal from "react-modal";
import { Link, NavLink } from "react-router-dom";
// import { useTranslation } from "react-i18next";
// import i18n from "../../i18n";

// import moreImage from "../../assets/images/more.svg";

//Modal component

// Scroll Top and bottom class function
function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState(null);

  React.useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? "down" : "up";
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener("scroll", updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener("scroll", updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

export default function Header() {
  //Scroll Add class
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
  }, []);

  //Language change
  // const [locale, setLocale] = useState(i18n.language);

  // i18n.on("languageChanged", (lng) => setLocale(i18n.language));
  // const handleChange = (e) => {
  //   i18n.changeLanguage(e.target.value); //en or de
  // };

  // const { t } = useTranslation();
  const scrollDirection = useScrollDirection();

  function HeaderIcon(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={42}
        height={24}
        fill="none"
        {...props}
      >
        <path
          fill="#2C72EE"
          d="M0 12h24c0 6.627-5.373 12-12 12S0 18.627 0 12Z"
        />
        <rect width={12} height={12} x={12} fill="#24A9EB" rx={6} />
        <rect width={12} height={12} x={30} y={12} fill="#008AFF" rx={6} />
        <path fill="#2D2CAD" d="M24 0h6v24h-6z" />
        <path
          fill="#306EFF"
          d="M0 12h24c0 6.627-5.373 12-12 12S0 18.627 0 12Z"
        />
        <rect width={12} height={12} x={12} fill="#FF9500" rx={6} />
        <path fill="#0F0DE1" d="M24 0h6v24h-6z" />
      </svg>
    );
  }

  //React Modal
  // const [modalIsOpen, setIsOpen] = React.useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }
  // function afterOpenModal() {}
  // function closeModal() {
  //   setIsOpen(false);
  // }

  return (
    // <div className={`header-section w-100 ${shadowStyle} ${hiddenStyle}`}>
    // <div
    //   className={
    //     "header-section w-100 flex align-center " +
    //     (scroll ? "header-active" : "")
    //   }
    // >
    <div
      className={`header-section w-100 ${
        scrollDirection === "down" ? "hhide" : "hshow"
      }`}
    >
      <div
        className={
          "header-section-content w-100 flex align-center " +
          (scroll ? "header-active" : "")
        }
        // value={{ locale }}
      >
        <div className="container flex justify-between">
          <Link to={"/"}>
            <HeaderIcon />
            {/* <p className="f-20 text-secondary f-w-400">
              ananda<span className="text-primary-color">.</span>
            </p> */}
          </Link>

          <div className="navbar-nav flex align-center mr-auto">
            <NavLink
              exact
              activeclassname="active"
              className="nav-link xs:flex"
              to="/"
            >
              <span />
              Home
            </NavLink>
            <NavLink
              exact
              activeclassname="active"
              className="nav-link xs:flex"
              to="/works"
            >
              <span />
              Works
            </NavLink>

            <NavLink
              exact
              activeclassname="active"
              to={"/about"}
              className="nav-link xs:flex"
            >
              <span />
              About
            </NavLink>
            {/* <NavLink
            exact
            activeclassname="active"
            to={"/resource"}
            className="nav-link xs:flex"
          >
            <span />
            Resource
          </NavLink> */}
            <NavLink
              exact
              activeclassname="active"
              to={"/resume"}
              className="nav-link xs:flex"
            >
              <span />
              Resume
            </NavLink>
          </div>
          {/* <div className="flex align-center" id="main"> */}
          {/* <div
              className="header-language flex"
              value={locale}
              onChange={handleChange}
            >
              <div className="relative image-26 l-h-26 text-center mr-4">
                <input
                  className="input"
                  htmlFor="en"
                  type="radio"
                  value="en"
                  name="language"
                />
                <label
                  id="en"
                  className="radio-label f-13 f-w-500 text-tertiary"
                >
                  EN
                </label>
              </div>

              <div className="relative image-26 l-h-26 text-center">
                <input
                  className="input"
                  htmlFor="de"
                  type="radio"
                  value="de"
                  name="language"
                />
                <label
                  id="de"
                  className="radio-label f-13 f-w-500 text-tertiary"
                >
                  DE
                </label>
              </div>
            </div> */}
          {/* <Link onClick={openModal} className="flex ml-16">
            <img src={moreImage} alt="more" />
          </Link> */}
          {/* <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >
            <div className="space-my-12">
              <div className="flex c-g-12">
                <div className="flex-1 bg-secondary radius-12 p-30"></div>
                <div className="flex-1 bg-secondary radius-12 p-30">Light</div>
                <div className="flex-1 bg-secondary radius-12 p-30">Dark</div>
              </div>
              <div className="flex-1 bg-secondary radius-12 p-30">
                Thendral vanthu
              </div>
              <div className="flex-1 bg-secondary radius-12 p-30">
                Love of my Life
              </div>
              <div className="flex-1 bg-secondary radius-12 p-30">Berge</div>
              <div className="flex-1 bg-secondary radius-12 p-30">
                Play Game
              </div>
            </div>
          </Modal> */}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
