import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";
import SplitType from "split-type";
import gsap from "gsap";
import OtherProjects from "../../components/Other-Projects/otherProjects";

export default function CRMProject() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".words1", { types: "chars" });
    const textA = SplitType.create(".sentence1", { types: "words" });
    const textB = SplitType.create(".line1", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.1 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -20, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  return (
    <div className="project-section crm-project container mt-120">
      <div className="project-hero-card bg-secondary radius-20 w-100 p-40 sm:p-20 xs:p-20 box1">
        <div className="project-hero-title mb-20 sm:mb-12 xs:mb-8">
          <h1 className="f-40 xs:f-18 xs:l-h-24 sm:f-32 l-h-40 f-w-600 text-primary words1">
            Maxis CRM
          </h1>
          <p className="f-16 xs:f-12 xs:l-h-20 sm:f-14 l-h-30 f-w-300 text-primary mt-8 sentence1">
            <span>CRM / Web and Mobile</span>
          </p>
        </div>
      </div>
      <div className="mt-50 flex sm:flex-column xs:flex-column">
        <div className="mw-600 twm-unset mr-40 sm:mr-0 xs:mr-0">
          <div>
            <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
              What is Maxis CRM?
            </h3>
            <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
              Maxis CRM helps the marketing and sales teams manage their
              business leads and customer management databases. It gives the
              entire team the ability to manage team task assignment and contact
              management. They can schedule meetings, phone calls, and email
              services through this platform.
            </p>
          </div>
          <div className="mt-30">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
              My Roles and responsibilities
            </h3>
            <div className="mt-12">
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
                As the lead UX designer for CRM, it is my responsibility to
                design a product that streamlines business operations, from data
                management to sales and marketing.
              </p>
              <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
                The user flow had to be finalised because CRM is a big platform
                with lots of flow and pages, which was the main challenge.
                Second, design a mobile app for the CRM.
              </p>
            </div>
          </div>
        </div>
        <div className="flex-1 sm:mt-24 xs:mt-20">
          <div className="bg-secondary w-100 radius-20 p-32 xs:p-12">
            <h3 className="f-16 l-h-26 f-w-600 text-secondary words1">
              Key Features
            </h3>
            <div className="mt-12">
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Create a Lead Management
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Appointment Scheduling, Task Assignment
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Sales Report Management, Deal Management
              </p>
              <p className="f-14 l-h-26 f-w-400 text-tertiary mt-12 line1">
                Email Communication, Email Templates, Email Scheduling
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-32 mw-860">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
          Key Analysis
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 mb-24 line1">
          There are many cloud-based CRM applications on the market, and among
          them, our major competitors are Zoho CRM, Freshsales, Hubspot, and
          Pipedrive. We were able to finalize the product's USP based on
          competitor research, and with that, myself and the product manager
          Finalized the product's user flow.
        </p>
      </div>
      <div className="crm-process-1 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 mb-24">
        <p className="bg-primary f-12 text-secondary px-12 py-6 radius-20 inline-block">
          Market Research
        </p>
      </div>
      <div className="crm-process-2 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 mb-24">
        <p className="bg-primary f-12 text-secondary px-12 py-6 radius-20 inline-block">
          Market analysis
        </p>
      </div>
      <div className="crm-process-3 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 mb-24"></div>
      <div className="crm-process-4 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200">
        <p className="bg-dark-1 f-12 text-secondary px-12 py-6 radius-20 inline-block">
          Minimal Project flow
        </p>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary mb-16">
          Design and handoff
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-6 mb-16 line1">
          Once we have completed the research phase. Initially, my team and I
          created the style guide and reusable components in Sketch software.
          CRM is one of company's products, so it was requested that it share
          some design cues with other products.
        </p>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-6 mb-24 line1">
          After the design was finished, we used Zelpin for design handoff. The
          sales and marketing teams at the office currently receive the product
          internally. Design iterations are continuing based on their feedback
          and suggestions.
        </p>
        <div className="mt-50 xs:mt-40 sm:mt-40 flex xs:flex-column">
          <div className="crm-img1 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:mr-0 mr-15 box1"></div>
          <div className="crm-img2 bg-secondary radius-20 p-32 xs:p-12 flex-1 minh-400 sm:minh-280 xs:minh-200 xs:ml-0 xs:mt-20 ml-15 box1"></div>
        </div>
        <div className="crm-img3 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="crm-img4 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 mt-32 box1"></div>
        <div className="crm-img5 bg-secondary radius-20 p-32 xs:p-12 minh-680 sm:minh-450 xs:minh-200 mt-32 box1"></div>
      </div>

      <div className="mt-32">
        <h3 className="f-16 l-h-26 f-w-600 text-secondary sentence1">
          Learning & Iteration
        </h3>
        <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mt-8 line1">
          We successfully tested our Beta phase and rolled out our internal
          customers. We are constantly receiving feedback from our customers and
          are working on the design iteration process. We used the design
          thinking process for all CRM project after learning about it through
          the agile UX process.
        </p>
      </div>

      <div className="text-center mt-60 mb-100 box1">
        <p className="f-20 mb-32 l-s-100">***</p>
        <OtherProjects />
      </div>
    </div>
  );
}
