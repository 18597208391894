import React from "react";
// import { useTranslation } from "react-i18next";

import exportImg1 from "../../assets/images/expertise-1.svg";
import exportImg2 from "../../assets/images/expertise-2.svg";
import exportImg3 from "../../assets/images/expertise-3.svg";
import exportImg4 from "../../assets/images/expertise-4.svg";

function ExpertSection() {
  // const { t } = useTranslation();
  return (
    <div className="flex bg-primary align-center justify-center w-100 pt-120 pb-120 sm:py-80 xs:py-60">
      <div className="container">
        <div className="hero-position flex flex-column align-center xs:align-start">
          <h3 className="f-32 xs:f-22 f-w-700 title-gradient my-8">
            .My areas of expertise
          </h3>
          <p className="f-18 sm:f-16 xs:f-14 l-h-30 f-w-300 text-tertiary text-center xs:text-left mw-580 mt-8">
            End-to-end User Interface Design and front-end integration.
          </p>

          <div className="flex sm:flex-column xs:flex-column c-g-30 r-g-30 w-100 mt-60 sm:mt-40 xs:mt-32">
            <div className="flex flex-1 xs:flex-column xs:r-g-30 c-g-30">
              <div className="flex-1">
                <div className="expert-card flex align-center justify-center p-30 bg-secondary radius-20">
                  <div className="image-130 grid place-center">
                    <img className="flex" src={exportImg1} alt="Branding" />
                  </div>
                </div>
                <div className="mt-20 text-center">
                  <h6 className="f-16 l-h-24 f-w-500 text-tertiary">
                    Branding{" "}
                  </h6>
                  {/* <p className="f-13 l-h-22 f-w-400 text-tertiary mt-12">
                  I've been curious about no-code platforms such as Webflow,
                  Framer, and others. Learning 3D, WebGL.
                </p> */}
                </div>
              </div>
              <div className="flex-1">
                <div className="expert-card flex flex-column align-center justify-center p-30 bg-secondary radius-20">
                  <div className="image-130 grid place-center">
                    <img
                      className="flex"
                      src={exportImg2}
                      alt="UI Interactions"
                    />
                  </div>
                </div>
                <div className="mt-20 text-center">
                  <h6 className="f-16 l-h-24 f-w-500 text-tertiary">
                    UI Interactions
                  </h6>
                  {/* <p className="f-13 l-h-22 f-w-400 text-tertiary mt-12">
                  I've been curious about no-code platforms such as Webflow,
                  Framer, and others. Learning 3D, WebGL.
                </p> */}
                </div>
              </div>
            </div>
            <div className="flex flex-1 xs:flex-column xs:r-g-30 c-g-30">
              <div className="flex-1">
                <div className="expert-card flex flex-column align-center justify-center p-30 bg-secondary radius-20">
                  <div className="image-130 grid place-center">
                    <img className="flex" src={exportImg3} alt="Experience" />
                  </div>
                </div>
                <div className="mt-20 text-center">
                  <h6 className="f-16 l-h-24 f-w-500 text-tertiary">
                    Experience
                  </h6>
                  {/* <p className="f-13 l-h-22 f-w-400 text-tertiary mt-12">
                  I've been curious about no-code platforms such as Webflow,
                  Framer, and others. Learning 3D, WebGL.
                </p> */}
                </div>
              </div>
              <div className="flex-1">
                <div className="expert-card flex flex-column align-center justify-center p-30 bg-secondary radius-20">
                  <div className="image-130 grid place-center">
                    <img
                      className="flex"
                      src={exportImg4}
                      alt="Frontend Development"
                    />
                  </div>
                </div>
                <div className="mt-20 text-center">
                  <h6 className="f-16 l-h-24 f-w-500 text-tertiary">
                    Front-end{" "}
                  </h6>
                  {/* <p className="f-13 l-h-22 f-w-400 text-tertiary mt-12">
                  I've been curious about no-code platforms such as Webflow,
                  Framer, and others. Learning 3D, WebGL.
                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpertSection;
