import React, { useLayoutEffect } from "react";
// import { useTranslation } from "react-i18next";

import SplitType from "split-type";
import gsap from "gsap";

const PlanningSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={226}
    height={278}
    fill="none"
    {...props}
  >
    <path fill="#6FB2EF" d="M0 63.925h161.299v63.925H0V63.925Z" />
    <path
      fill="#302EFF"
      d="M75.075 0h150.15v63.925H75.075V0ZM75.075 127.85h150.15V278H75.075V127.85Z"
    />
    <path fill="#1C294B" d="M0 202.925h75.075V278H0v-75.075Z" />
    <path fill="#fff" d="M75.075 0 35.307 33.078 0 63.925h75.075V0Z" />
    <path
      fill="#1C294B"
      d="M225.225 95.888c0 17.652-14.311 31.962-31.963 31.962s-31.963-14.31-31.963-31.962c0-17.653 14.311-31.963 31.963-31.963s31.963 14.31 31.963 31.963Z"
    />
    <path
      fill="#fff"
      d="M75.075 165.388c0 20.731-16.806 37.537-37.538 37.537C16.807 202.925 0 186.119 0 165.388c0-20.732 16.806-37.538 37.537-37.538 20.732 0 37.538 16.806 37.538 37.538Z"
    />
  </svg>
);

const DevelopmentSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={229}
    height={278}
    fill="none"
    {...props}
  >
    <path fill="#CEE6F9" d="M.75 0H229v77.39H.75V0Z" />
    <path
      fill="#F4F9FF"
      d="M222.243 141.254c0 56.85-46.053 102.935-102.863 102.935-56.809 0-102.862-46.085-102.862-102.935S62.571 38.319 119.38 38.319c56.81 0 102.863 46.085 102.863 102.935ZM39.793 260.343c0 9.752-7.9 17.657-17.644 17.657-9.745 0-17.644-7.905-17.644-17.657 0-9.751 7.9-17.657 17.644-17.657 9.745 0 17.644 7.906 17.644 17.657Z"
    />
    <path
      fill="#306EFF"
      d="M0 100.681h70.577l63.82 70.627H0v-70.627ZM88.597 189.341H229v87.908H88.597v-87.908Z"
    />
    <path fill="#FF9500" d="M75.082 212.632H46.551v64.617h28.531v-64.617Z" />
  </svg>
);

export default function Resume() {
  // const { t } = useTranslation();

  useLayoutEffect(() => {
    const text = new SplitType(".resume1", { types: "chars" });
    const textA = SplitType.create(".resume2", { types: "words" });
    const textB = SplitType.create(".resume3", { types: "lines" });

    gsap.fromTo(
      text.chars,
      { y: 0, opacity: 0 },
      { y: -12, opacity: 1, stagger: 0.03 }
    );
    gsap.fromTo(
      textA.words,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.06 }
    );
    gsap.fromTo(
      textB.lines,
      { y: 0, opacity: 0, delay: 0.5 },
      { y: -12, opacity: 1, stagger: 0.2 }
    );

    gsap.fromTo(
      ".box1",
      { y: 0, opacity: 0, delay: 1 },
      { y: -24, opacity: 2, duration: 1 }
    );

    gsap.config({
      force3D: false,
      nullTargetWarn: false,
      trialWarn: false,
    });
  });

  function DownloadIcon(props) {
    return (
      <svg
        width={21}
        height={22}
        viewBox="0 0 31 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M29.481 21v6.667c0 1.84-1.417 3.333-3.165 3.333H4.166C2.417 31 1 29.508 1 27.667V21M7.33 12.667L15.24 21l7.912-8.333M15.24 21V1"
          stroke="#4F5664"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
  return (
    <div className="other-page-section resume-page flex justify-center pt-160 pb-100 xs:pt-120">
      <div className="container-resume xs:mw-none">
        <div className="bg-primary pt-60 pb-24 px-40 xs:pt-50 xs:px-20 radius-20">
          <p className="page-summery-text f-14 l-h-24 l-s-20 f-w-700 text-secondary text-uppercase mb-6 pl-16 resume1">
            Resume and Cover letter
          </p>
          <h1 className="f-80 f-w-700 text-secondary xs:f-32 sm:f-40 resume1">
            Download.
          </h1>
          {/* <p className="f-16 xs:f-14 xs:l-h-24 l-h-26 f-w-400 text-tertiary mw-660 mt-12 mb-32 resume3">
            In publishing and graphic design, Lorem ipsum is a placeholder text
            commonly used to demonstrate the visual form of a document or a
            typeface without relying on meaningful content. Lorem ipsum may be
            used as a placeholder before final copy is available.
          </p> */}
          {/* <p className="f-16 l-h-26 f-w-400 text-tertiary mt-24 mw-750 resume3">
            {t("resume.desc")}
          </p> */}
          <div className="flex mt-32">
            <div className="flex-1 pr-30 xs:pr-0">
              {/* <p className="f-14 l-h-24 f-w-400 text-tertiary mb-32 resume1">
                {t("resume.english")}
              </p> */}
              <div className="flex xs:flex-column">
                <a
                  className="flex-1  download-btn bg-secondary radius-10 flex align-center justify-between px-32 py-18 box1 mr-12 xs:mr-0 xs:mb-12"
                  href="https://drive.google.com/file/d/1-YFQVh7S39-fHxCU9RtZP5pxb05NKwRi/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="f-20 l-h-36 text-secondary">Resume</p>
                  <DownloadIcon />
                </a>
                <a
                  className="flex-1 download-btn bg-secondary radius-10 flex align-center justify-between px-32 py-18 box1 ml-12 xs:ml-0"
                  href="https://drive.google.com/file/d/1-pESd-24M7onnGxwMXXe0iak9vfnJNjW/view?usp=sharing"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="f-20 l-h-36 text-secondary">Cover Letter</p>
                  <DownloadIcon />
                </a>
              </div>
            </div>
            {/* <div className="flex-1 pl-30">
              <p className="f-14 l-h-24 f-w-400 text-tertiary mb-32 resume1">
                {t("resume.german")}
              </p>
              <a
                className="download-btn bg-secondary radius-10 flex align-center justify-between px-32 py-18 box1"
                href="https://drive.google.com/file/d/1__HA-wjsXNSdd93buwHp_TvLy0JZvIA5/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                <p className="f-20 l-h-36 text-secondary">
                  {t("resume.resume")}
                </p>
                <DownloadIcon />
              </a>
              <a
                className="download-btn bg-secondary radius-10 flex align-center justify-between px-32 py-18 mt-16 box1"
                href="https://drive.google.com/file/d/1ULFhGsxxGcpKm_N3aqy7fwPKuG8yYvVD/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                <p className="f-20 l-h-36 text-secondary">
                  {t("resume.cover")}
                </p>
                <DownloadIcon />
              </a>
            </div> */}
          </div>
        </div>
        <div className="pt-100">
          <div className="flex flex-column align-center mb-32">
            <h3 className="f-32 xs:f-22 f-w-700 text-secondary my-8 resume3">
              .Tools and Techstack
            </h3>
            <p className="f-16 xs:f-14 l-h-30 f-w-300 text-tertiary text-center mt-4 resume3">
              I've had a lot of fun and learning along these tech stacks over
              the years.
            </p>
          </div>

          <div className="techstack-card bg-primary radius-20 flex xs:flex-column mb-40">
            <div className="flex-1 grid place-center border-right-exlight xs:border-none py-100 px-60 xs:py-40 xs:px-20">
              <p className="f-20 l-h-36 f-w-700 text-primary mb-40 resume3">
                Planning and Design
              </p>
              <div className="box1">
                <PlanningSvg />
              </div>
            </div>
            <div className="flex-1 py-100 px-60 xs:py-40 xs:px-20">
              <div>
                <p className="f-14 xs:f-12 l-h-24 f-w-400 text-tertiary text-uppercase resume3">
                  Design Management
                </p>
                <p className="f-18 xs:f-14 xs:l-h-24 l-h-36 f-w-500 text-secondary mt-10 resume2">
                  Stakeholder Interview, UX Research, Brand Identify, UI Design,
                  Wireframe, Prototype
                </p>
              </div>
              <div className="mt-40">
                <p className="f-14 xs:f-12 l-h-24 f-w-400 text-tertiary text-uppercase resume3">
                  Design Tools
                </p>
                <p className="f-18 xs:f-14 xs:l-h-24 l-h-36 f-w-500 text-secondary mt-10 resume2">
                  Figma, Framer, Sketch, Webflow, Adobe XD, Photoshop,
                  Illustrator, Miro, Notion, Zeplin
                </p>
              </div>
              <div className="mt-40">
                <p className="f-14 xs:f-12 l-h-24 f-w-400 text-tertiary text-uppercase resume3">
                  Team and Process
                </p>
                <p className="f-18 xs:f-14 xs:l-h-24 l-h-36 f-w-500 text-secondary mt-10 resume2">
                  Agile, Design Scrum and Sprint, Design systems, Mentoring,
                  Leading
                </p>
              </div>
            </div>
          </div>

          <div className="techstack-card bg-primary radius-20 flex xs:flex-column">
            <div className="flex-1 py-100 px-60 xs:py-40 xs:px-20">
              <div>
                <p className="f-14 xs:f-12 l-h-24 f-w-400 text-tertiary text-uppercase resume3">
                  Development
                </p>
                <p className="f-18 xs:f-14 xs:l-h-24 l-h-36 f-w-500 text-secondary mt-10 resume2">
                  Html, CSS/SCSS/LESS/PostCSS, JS/TypeScript, React.js,
                  Angular.js, Wordpress, CMS, No-code
                </p>
              </div>
              <div className="mt-40">
                <p className="f-14 xs:f-12 l-h-24 f-w-400 text-tertiary text-uppercase resume3">
                  FRAMEWORKS
                </p>
                <p className="f-18 xs:f-14 xs:l-h-24 l-h-36 f-w-500 text-secondary mt-10 resume2">
                  Tailwind, Bootstrap, styled components, Angular Matrial, React
                  Matreial, Ant Design
                </p>
              </div>
              <div className="mt-40">
                <p className="f-14 xs:f-12 l-h-24 f-w-400 text-tertiary text-uppercase resume3">
                  Task management
                </p>
                <p className="f-18 xs:f-14 xs:l-h-24 l-h-36 f-w-500 text-secondary mt-10 resume2">
                  Git, Jira, Trello, Asana, Notion
                </p>
              </div>
            </div>
            <p className="flex-1 grid place-center border-left-exlight xs:border-none py-100 px-60 xs:py-40 xs:px-20">
              <p className="f-20 l-h-36 f-w-700 text-primary mb-40 resume3">
                Frontend Development
              </p>
              <div className="box1">
                <DevelopmentSVG />
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
