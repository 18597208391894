import React from "react";
// import {
//   Accordion,
//   AccordionItem,
//   AccordionItemHeading,
//   AccordionItemButton,
//   AccordionItemPanel,
// } from "react-accessible-accordion";

// Demo styles, see 'Styles' section below for some notes on use.
// import "react-accessible-accordion/dist/fancy-example.css";
// import { useTranslation } from "react-i18next";

// const FaqDataSection = [
//   {
//     faqKey: 1,
//     faqQuestion: "What is your process?",
//     faqAnswer:
//       "I will start the similarly (Understand, Research/Analysis, Wireframe/Prototype/Design, Testing/Validating). I have worked many projects with different timeline. Based on that i could fluctuate the process according to project demands and deadlines.",
//   },
//   {
//     faqKey: 2,
//     faqQuestion: "Are you a developer?",
//     faqAnswer:
//       "Since 2016, I have been working on front-end applications using HTML/SCSS, JavaScript/jQuery, React.js/Angular, and REST/JSON. I enjoy working on dashboard apps and landing pages.",
//   },
//   {
//     faqKey: 3,
//     faqQuestion: "How can you add value into our business?",
//     faqAnswer:
//       "I’m a designer-come developer. I have working experience in B2C, B2B, and SaaS products. Also I have good experience on design management and frontend integration over the years. i can definitely bring best solutions for the business.",
//   },
//   {
//     faqKey: 4,
//     faqQuestion: "What you need to get start a new projects?",
//     faqAnswer:
//       "Just wanna make sure the stakeholder idea could match with the targeted users with project documentation. Also i just need paper, pencil, cup of coffee to kick start the design ideas and process.",
//   },
//   {
//     faqKey: 5,
//     faqQuestion: "What are the current learnings?",
//     faqAnswer:
//       "I've been curious about no-code platforms such as Webflow, Framer, and others. Learning 3D, WebGL, and Metaverse platforms as well. Also interest to learning React architecture/Next.js on my pipeline.",
//   },
// ];

function FaqSection() {
  // const { t } = useTranslation();
  return (
    <div className="faq-section pt-100 pb-100 sm:py-80 xs:pt-60 xs:pb-40">
      <div className="container">
        {/* {FaqDataSection.map((item) => (
          <Accordion allowZeroExpanded key={item.faqKey}>
            <AccordionItem>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h6 className="f-14 f-w-700 text-secondary mb-6">
                    {item.faqQuestion}
                  </h6>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p className="f-13 l-h-26 f-w-300 text-tertiary mw-630">
                  {item.faqAnswer}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        ))} */}
        <h3 className="f-32 xs:f-22 f-w-700 title-gradient my-8">
          .Clarification about me
        </h3>
        <div className="flex xs:flex-column xs:r-g-24 c-g-50 mt-50">
          <div className="flex-1">
            <h6 className="f-14 f-w-700 text-secondary mb-6">
              What is your process?
            </h6>
            <p className="f-13 l-h-26 f-w-300 text-tertiary mw-630">
              I will start the similarly (Understand, Research/Analysis,
              Wireframe/Prototype/Design, Testing/Validating). I have worked
              many projects with different timeline. Based on that i could
              fluctuate the process according to project demands and deadlines.
            </p>
          </div>
          <div className="flex-1">
            <h6 className="f-14 f-w-700 text-secondary mb-6">
              Are you a developer?
            </h6>
            <p className="f-13 l-h-26 f-w-300 text-tertiary mw-630">
              Since 2016, I have been working on front-end applications using
              HTML/SCSS, JavaScript/jQuery, React.js/Angular, and REST/JSON. I
              enjoy working on dashboard apps and landing pages.{" "}
            </p>
          </div>
        </div>

        <div className="flex xs:flex-column xs:r-g-24 c-g-50 my-42 sm:my-32 xs:my-24">
          <div className="flex-1">
            <h6 className="f-14 f-w-700 text-secondary mb-6">
              How can you add value into our business?{" "}
            </h6>
            <p className="f-13 l-h-26 f-w-300 text-tertiary mw-630">
              I’m a designer-come developer. I have working experience in B2C,
              B2B, and SaaS products. Also I have good experience on design
              management and frontend integration over the years. i can
              definitely bring best solutions for the business.
            </p>
          </div>

          <div className="flex-1">
            <h6 className="f-14 f-w-700 text-secondary mb-6">
              What you need to get start a new projects?{" "}
            </h6>
            <p className="f-13 l-h-26 f-w-300 text-tertiary mw-630">
              Just wanna make sure the stakeholder idea could match with the
              targeted users with project documentation. Also i just need paper,
              pencil, cup of coffee to kick start the design ideas and process.
            </p>
          </div>
        </div>

        <div className="flex xs:flex-column xs:r-g-24 c-g-50">
          <div className="flex-1">
            <h6 className="f-14 f-w-700 text-secondary mb-6">
              What are the current learnings?
            </h6>
            <p className="f-13 l-h-26 f-w-300 text-tertiary mw-630">
              I've been curious about no-code platforms such as Webflow, Framer,
              and others. Learning 3D, WebGL, and Metaverse platforms as well.
              Also interest to learning React architecture/Next.js on my
              pipeline.
            </p>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
    </div>
  );
}

export default FaqSection;
